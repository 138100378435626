@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  background: linear-gradient(
    to right,
    #6ee7b7,
    #ccff33
  ); /* Customize your gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text2 {
  background: linear-gradient(
    to right,
    #ffc700,
    #faf0e6
  ); /* Customize your gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}
.v {
  backdrop-filter: blur(50px);
}
.social {
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}
.social4:hover {
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}

.resume {
  position: relative;
  width: 100px;
  height: 50px;
  margin-top: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgb(25, 26, 25, 0.2);
  box-shadow: 0 0 5px rgb(233, 242, 227);
  transition: all 2s ease-in-out;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 0;
}
.resume::before {
  content: "";
  position: absolute;
  left: -25px;
  top: -10px;
  width: 150%;
  height: 150%;
  background-image: conic-gradient(rgb(240, 233, 22) 30deg, transparent 140deg);
  z-index: -1;
  animation: rotate2 2s linear infinite;
}

.resume::after {
  position: absolute;
  inset: 2px;
  content: "";
  z-index: -1;
  border-radius: 0.5rem;
  background-color: rgb(19, 19, 18, 0.3);
  box-shadow: 0 0 5px rgb(233, 242, 227);
}

.about-container {
  position: relative;
  width: 100%;
  margin: 0% auto;
  top: 40px;
  border: 3px solid white;
  border-radius: 1rem 4rem 1rem 4rem;
  box-shadow: inset 2px 10px 30px rgba(243, 235, 7, 0.5);
}
.about {
  position: absolute;
  width: 80%;
  height: 400px;
  margin-left: 100px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(18px);
  border: 4px solid rgba(255, 255, 255, 0.5);
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 30px;

  border-radius: 1rem;
  overflow: hidden;
}
.giphy-embed {
  margin-left: 100px;
  width: 80%;
  height: 400px;
  border-radius: 10px;
}
@media screen and (max-width: 780px) {
  .about {
    left: 0px; /* Apply left positioning */

    font-size: small;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 10px;

    /* Use absolute positioning if needed */
  }

  .about-container {
    position: relative;
    left: -65px;
    top: 40px;
    border: none;
    box-shadow: none;
  }
}
.card-inside {
  background-image: conic-gradient(rgb(234, 179, 8, 0.2), transparent 360deg);
}
