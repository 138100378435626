.j {
  margin-top: 110%;
}

.active-link {
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}
.tl {
  margin-top: 100px;
}
.t2 {
  margin-bottom: 50px;
}
.t3 {
  margin-top: 130px;
}
.t4 {
  height: 80vh;
}

.animate {
  transition: all 0.3s ease-in-out;
}
.nav {
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  border-radius: 10px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}
.nav:hover {
  border-radius: 10px;
  box-shadow: inset 2px 10px 20px rgba(243, 235, 7, 0.5);

  backdrop-filter: blur(18px);
}
