.image {
  border-radius: 5% 25%;
  border-top: 8px solid rgba(255, 255, 255, 0.5);
  border-right: 5px solid yellow;
  border-left: 5px solid yellow;
  border-bottom: 8px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: inset 2px 10px 20px rgba(243, 235, 7, 0.5);
  filter: contrast(1.1);
}
